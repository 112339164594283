footer {
    text-align: center;
    font-size: 14px;
    color: #000;
    opacity: 0.5;
    margin-top: auto;
    //  border-top: 1px solid #e4e4e4;
    padding-top: 0.5rem;
    p {
        margin-bottom: 0.5rem;
    }
}